import React from "react";
import Head from "next/head";
import { loadStripe } from '@stripe/stripe-js';
import {
  ResponsiveContext,
  Main,
  Header,
  Footer,
  Box,
  Heading,
  Paragraph,
  Button,
  Text,
  Image,
  Anchor,
} from "grommet";
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';

const CalloutPill = styled.span`
  font-size: 0.5em;
  border: 2px solid #FF727A;
  border-radius: 20px;
  padding: 3px 10px;
  color: #FF727A;
  text-transform: uppercase;
  whitespace: nowrap;
`

const redirectToCheckout = async () => {
  const stripe = await loadStripe(process.env.NEXT_PUBLIC_STRIPE_TOKEN);
  const result = await stripe.redirectToCheckout({
    lineItems: [{ price: process.env.NEXT_PUBLIC_STRIPE_PRICE_ID, quantity: 1 }],
    mode: 'payment',
    // Do not rely on the redirect to the successUrl for fulfilling
    // purchases, customers may not always reach the success_url after
    // a successful payment.
    // Instead use one of the strategies described in
    // https://stripe.com/docs/payments/checkout/fulfillment
    successUrl: process.env.NEXT_PUBLIC_SUCCESS_URL,
    cancelUrl: process.env.NEXT_PUBLIC_CANCELLED_URL,
    shippingAddressCollection: {
      allowedCountries: ['GB'],
    }
  });
  if (result.error) {
    alert(result.error.message);
  }
}

const PaymentWidget = ({ alignSelf }) => (
  <Box width="300px" alignSelf={alignSelf} pad={{ bottom: "medium" }}>
    <Button primary disabled label="Sold out" size="large" />
  </Box>
);

export default function Home() {
  return (
    <ResponsiveContext.Consumer>
      {(screenSize) => (
        <>
          <Box direction="row-responsive" justify="between">
            <Box>
              <Heading level={3} margin={{ vertical: "small" }}>
                <CalloutPill>Sold Out</CalloutPill>
              </Heading>
              <Heading level={3} margin={{ vertical: "none" }}>
                Original Elephant Morby
              </Heading>
              <Heading level={4} margin={{ top: "small" }}>15cm Sofubi + Resin Toy</Heading>
            </Box>
            <PaymentWidget alignSelf="end" />
          </Box>
          <ImageGallery items={[
            {
              original: "product_drawing.png",
              thumbnail: "product_drawing_thumb.png",
            },
            {
              original: "product_model.png",
              thumbnail: "product_model_thumb.png",
            },
            {
              original: "product_forest.png",
              thumbnail: "product_forest_thumb.png",
            },
            {
              original: "product_whales.png",
              thumbnail: "product_whales_thumb.png",
            },
          ]}
          infinite={false}
          showNav={false}
          showFullscreenButton={false}
          showPlayButton={false}
           />
          <Box align="center">
            <Heading level={3} margin={{ bottom: "none" }}>
              The story of Morby
            </Heading>
            <Paragraph>
              A fairy tale has become a reality, a dream lighting up the path
              ahead.
            </Paragraph>
            <Paragraph>
              In the vast ocean, there is a forgotten continent, where a magical
              world of infinite possibilities exists. Little Elephant Morby and
              his family lived happily in the forest in that forgotten land. Their
              family represents the peace and happiness of that place, guarding
              every corner of the continent, looking after every happy animal
              living there.
            </Paragraph>
            <Paragraph>
              Today is Elephant Morby's first trip out of the forest to fulfill
              his destiny as keeper of the land. He looks forward to spreading
              rainbows and stars across the skies, where whales can fly up to
              catch them.
            </Paragraph>
            <Paragraph>
              Elephant Morby is looking forward to meeting more friends and
              exploring even further. Are you ready to follow him on his
              adventures across this magical land?
            </Paragraph>
          </Box>
          <Box fill={false} flex={false} height="medium" margin={{ top: "small" }}>
            <Image src="story_morby.jpg" fill={true} fit="contain" alignSelf="center" />
          </Box>
          <Box align="center" margin={{ top: "medium" }}>
            <PaymentWidget alignSelf="center" />
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}
